import config from "../config"

export default function ApiServices() {


    return {

        async getJobDetail(job_id: string) {


            return fetch(config.api.miniprofile + "jobs/"  + job_id, {
                method: 'GET', headers: {
                    'authorization' : 'Basic bWVzc3VhOnBpbmd1MTIz',
                    'Content-Type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },

        async fetchImgUrl(imgurl: string) {


            return fetch(imgurl, {
                method: 'GET', headers: {
                    "access-control-allow-origin" : "*"
                }
            }
            ).then(res => res.blob()).catch(err => console.log(err))
        },

        async saveCompany(values: any) {

            return fetch(config.api.miniprofile+config.api.register_company, {
                method: 'POST', headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            }
            ).then(res => res.blob()).catch(err => console.log(err))
        },

        async postJob(values: any) {

            return fetch(config.api.miniprofile+config.api.post_job, {
                method: 'POST', headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            }
            ).then(res => res.blob()).catch(err => console.log(err))
        },
        async getJobList() {

            return fetch(config.api.miniprofile + "jobs/", {
                method: 'GET', headers: {
                    'authorization' : 'Basic bWVzc3VhOnBpbmd1MTIz',
                    'Content-Type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async getCompanyList() {

            return fetch(config.api.miniprofile + config.api.list_company, {
                method: 'GET', headers: {
                    'authorization' : 'Basic bWVzc3VhOnBpbmd1MTIz',
                    'Content-Type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },

        async ApplyJobs(values: any, jobId: any) {

            return fetch(config.api.miniprofile+config.api.apply_job + jobId+ "/apply", {
                method: 'POST', headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            }
            ).then(res => res.blob()).catch(err => console.log(err))
        }
    }
}