import React, { useCallback, useRef, useState } from "react";
import JobDetailModel from "../../model/job-model";
import ApiServices from "../../services/api";
import "./share-job.scss";

import LOCATION from "../../assets/images/location.png";
import { QRCodeSVG } from "qrcode.react";
import exportAsImage from "../../services/exportAsImage";
import { backgroundImage } from "html2canvas/dist/types/css/property-descriptors/background-image";
import config from "../../config";
import storage from "../../services/firebase-config";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-toastify/dist/ReactToastify.css';
import {
    getDownloadURL,
    ref,
    uploadBytesResumable
} from "firebase/storage";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
var domtoimage = require("dom-to-image-more");
const ShareJobComponent = () => {
    let navigate = useNavigate();
    const ref2 = useRef(null)
    const canvasRef = useRef(null);
    const [file, setFile] = useState<File>();
    const [percent, setPercent] = useState(0);
    const [jobDetailModel, setJobDetailModel] = useState<JobDetailModel>();
    let job_id = (new URLSearchParams(window.location.search)).get("job_id")
    const [benefit, setBenefit] = useState<String[]>();
    const notify = () => toast("Copy Url แล้ว");
    React.useEffect(() => {
        console.log(job_id);

        getJobDetail();

    }, [])
    const benefitsArray = [
        {
            id: "EDUCATION_ALLOWANCE",
            value: "ทุนเพื่อการศึกษา"
        },
        {
            id: "FLEXIBLE_WORKING_HOUR",
            value: "เวลาการทำงานที่ยืดหยุ่น"
        },
        {
            id: "FREE_SHUTTLE_BUS",
            value: "บริการรถรับส่งพนักงาน"
        },
        {
            id: "HOUSING_ALLOWANCE",
            value: "ค่าใช้จ่ายสำหรับที่อยู่อาศัย"
        },
        {
            id: "TRAVEL_ALLOWANCE",
            value: "ค่าใช้จ่ายในการเดินทาง"
        },
        {
            id: "OVERTIME_PAY",
            value: "ค่าทำงานล่วงเวลา"
        },
        {
            id: "MEDICAL_INSURANCE",
            value: "ประกันสุขภาพ"
        },
        {
            id: "LIFE_INSURANCE",
            value: "ประกันชีวิต"
        },
        {
            id: "DENTAL_INSURANCE",
            value: "สิทธิการเบิกค่าทันตกรรม"
        },
        {
            id: "GRATUITY",
            value: "ค่าตอบแทนพิเศษ"
        },
        {
            id: "WORK_FROM_HOME",
            value: "ทำงานจากที่บ้าน"
        },
        {
            id: "FIVE_DAY_WORK_WEEK",
            value: "ทำงานสัปดาห์ละ 5 วัน"
        },
        {
            id: "TRANSPORTATION_ALLOWANCE",
            value: "ค่ายานพาหนะ"
        },
        {
            id: "PERFORMANCE_BONUS",
            value: "เงินโบนัสตามผลงาน"
        },
        {
            id: "ACCOMMODATION",
            value: "ที่พักพนักงาน"
        },
        {
            id: "OTHER",
            value: "อื่นๆ"
        }
    ]

    const getJobDetail = () => {

        if (job_id) {
            ApiServices().getJobDetail(job_id).then((res: JobDetailModel) => {
                console.log(res);
                setJobDetailModel(res);

                const arrayBenefits = new Array<String>();
                res.benefits?.map((res: any, index: any) => {
                    benefitsArray.map((res2: any) => {
                        console.log(res2.id);
                        if (res === res2.id) {
                            arrayBenefits.push(res2.value);
                            console.log(res2.value);
                        }
                    })


                })

                setBenefit(arrayBenefits);

            })
        }

    }
    function createMarkup(data: string) {
        return { __html: data };
    }


    const handleUpload = (values) => {
        if (!file) {
            alert("Please upload an image first!");
        } else {
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    setPercent(percent);
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        const resValue = values;
                        resValue.logo = url;


                    });
                }
            );
        }


    };


    return (
        <>
            <div className="container d-flex flex-column home ">

                <div className="frame-option " ref={ref2}>

                    <div className="logo-grid-control">

                        <div className="d-flex flex-column">


                            <span className="company-name">บริษัท {jobDetailModel?.companyName}</span>
                            {/* <span className="company-name">{jobDetailModel?.headquarterName}</span> */}
                            <div className="divider"></div>
                            <span className="job-title">{jobDetailModel?.name}</span>

                        </div>
                        {/* <canvas ref={canvasRef} className="logo-img"></canvas> */}
                        <img src={jobDetailModel?.companyLogoImageUrl} className="logo-img" ></img>
                        {/* <div className="logo-img" style={{backgroundImage : `url("${jobDetailModel?.headquarterLogoUrl}")`}}></div> */}
                    </div>

                    {
                        jobDetailModel?.salaryTo === jobDetailModel?.salaryFrom ?
                            <span className="salary">{jobDetailModel?.salaryFrom.toLocaleString()} บาท / {
                                jobDetailModel?.salaryType === "DAILY" ?

                                    "วัน" : jobDetailModel?.salaryType === "MONTHLY" ? "เดือน"
                                        : jobDetailModel?.salaryType === "HOURLY" ? "ชั่วโมง"
                                            : "ปี"


                            }</span> :

                            <span className="salary">{jobDetailModel?.salaryFrom.toLocaleString()} - {jobDetailModel?.salaryTo.toLocaleString()} บาท / {
                                jobDetailModel?.salaryType === "DAILY" ?

                                    "วัน" : jobDetailModel?.salaryType === "MONTHLY" ? "เดือน"
                                        : jobDetailModel?.salaryType === "HOURLY" ? "ชั่วโมง"
                                            : "ปี"


                            }</span>

                    }

                    <div className="d-flex">
                        <span className="position">เปิดรับ {jobDetailModel?.openPosition} ตำแหน่ง</span>
                        <div className="divider-2"></div>
                        <span className="position-type">{
                            jobDetailModel?.jobType === "FULL_TIME" ? "งานประจำ" :
                                jobDetailModel?.jobType === "PART_TIME" ? "งานพาร์ทไทม์" : "งานสัญญาจ้าง"

                        }</span>
                    </div>
                    <div className="d-flex location-control">
                        <img src={LOCATION} className="location-img"></img>
                        <span className="location-text">{jobDetailModel?.googlePlaceName}</span>
                    </div>

                    <div className="bottom-control">

                        <div className="d-flex flex-column">
                            {
                                jobDetailModel?.benefits ?

                                    <div className="d-flex flex-column">

                                        <span className="header-text">สวัสดิการ</span>

                                        <div className="benefit-control">

                                            {/* <span className="desc-text" dangerouslySetInnerHTML={createMarkup(jobDetailModel?.benefits + "")}></span> */}
                                            {
                                                benefit?.map((res: String, index: any) => {

                                                    return (

                                                        <li className="desc-text">
                                                            {res.replace("</li>", "")}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div> : null
                            }

                            <span className="header-text">คุณสมบัติ</span>
                            {
                                jobDetailModel?.educationFrom ?
                                    <li className="desc-text">

                                        {
                                            jobDetailModel?.educationFrom.nameTH === "ไม่ระบุ" ?

                                                "ไม่ระบุการศึกษาขั้นต่ำ" :

                                                "การศึกษาระดับ" + jobDetailModel?.educationFrom.nameTH
                                        }

                                    </li> :  <li className="desc-text">ไม่ระบุการศึกษาขั้นต่ำ</li>

                            }
                            {
                                jobDetailModel?.experienceFrom ?
                                    <li className="desc-text">

                                        {
                                            "ประสบการณ์ขั้นต่ำ " + jobDetailModel?.experienceFrom + " ปี"
                                        }

                                    </li> :  <li className="desc-text">ไม่ระบุประสบการณ์ขั้นต่ำ</li>
                            }

                            {

                                jobDetailModel?.bikeLicense ?

                                    <li className="desc-text">มีใบขับขี่จักรยานยนต์
                                    </li> : null
                            }

                            {

                                jobDetailModel?.carLicense ?

                                    <li className="desc-text">มีใบขับขี่รถยนต์
                                    </li> : null
                            }
                            {

                                jobDetailModel?.truckLicense ?

                                    <li className="desc-text">มีใบขับขี่รถบรรทุก
                                    </li> : null
                            }

                            {

                                jobDetailModel?.gender === "MALE" ?

                                    <li className="desc-text">เพศชาย
                                    </li> : jobDetailModel?.gender === "FEMALE" ? <li className="desc-text">เพศหญิง
                                    </li> : <li className="desc-text">ไม่ระบุเพศ
                                    </li>
                            }

                            {
                                jobDetailModel?.ageTo ?
                                    jobDetailModel?.ageFrom === jobDetailModel?.ageTo ?
                                        <li className="desc-text">อายุ
                                            {jobDetailModel?.ageTo} ปี
                                        </li> : <li className="desc-text">อายุระหว่าง {jobDetailModel?.ageFrom} - {jobDetailModel?.ageTo} ปี
                                        </li>
                                    : null

                            }




                        </div>

                        <div className="d-flex flex-column mt-auto">
                            <span className="register-now">สมัครเลย</span>
                            <QRCodeSVG className="qr-img" value={config.link.apply + job_id} bgColor='transparent' />
                        </div>
                    </div>



                </div>
                <CopyToClipboard text={config.link.apply + job_id}
                >
                    <button className="btn mx-auto button-download" onClick={notify} >Copy Link งาน</button>
                </CopyToClipboard>
                <ToastContainer position="bottom-center" autoClose={2000} />
            </div>
        </>
    )

}

export default ShareJobComponent;