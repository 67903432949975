
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import React from 'react';
import DESKTOP_IMG from "../assets/images/install-metamask-modal.png";
import "./complete.scss";
import Lottie from "lottie-react";
import LoadingGif from "../assets/json/complete.json";
const CompleteModal = ({ isOpen, onOpen, onClose, isRegister, isPostJob, isApply }: any) => {
    const [size, setSize] = React.useState('xxl')

    const messageIsRegisterObj = {
        header: "ลงทะเบียนบริษัทสำเร็จ!",
        desc: "ระบบจะทำการตรวจสอบข้อมูลภายใน 6 ชั่วโมง โดยคุณจะสามารถเริ่มโพสต์งานได้ เมื่อได้รับการแจ้งเตือนนะคะ"
    }

    const messageIsPostJobObj = {
        header: "ขอบคุณสำหรับข้อมูลตำแหน่งงานที่ต้องการเปิดรับนะคะ",
        desc: "เราจะรีบดำเนินการค้นหาผุ้สมัครที่ใช่ให้คุณ คุณจะได้รับการแจ้งเตือนทันที เมื่อมีผู้สมัครที่ตรงตามคุณสมบัติที่คุณต้องการ"
    }
    const messageIsApplyObj = {
        header: "สมัครงานสำเร็จ!",
        desc: "หากผู้ว่าจ้างสนใจโปรไฟล์ของคุณ คุณจะได้รับการติดต่อกลับทางเบอร์โทรศัพท์ที่ระบุไว้"
    }

    React.useEffect(() => {
        console.log(messageIsRegisterObj.header);
    }, [])

    const reload = () => {

        window.location.reload();
    }

    return (

        <>

            <Modal closeOnOverlayClick={false} size={size} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg='blackAlpha.800'
                    backdropFilter='blur(10px)' />
                <ModalContent className="complete ">
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <ModalBody className="border-control">
                        <div className="container  d-flex flex-column loading-control my-auto">
                            <Lottie animationData={LoadingGif} loop={true} autoPlay={true} className="lottie-control" />

                            {
                                isRegister ?
                                    <div className='d-flex flex-column'>
                                        <span className='text-header'>{messageIsRegisterObj.header}</span>
                                        <span className='text-desc'>{messageIsRegisterObj.desc}</span>
                                    </div> :
                                    isApply ?
                                        <div className='d-flex flex-column'>
                                            <span className='text-header'>{messageIsApplyObj.header}</span>
                                            <span className='text-desc'>{messageIsApplyObj.desc}</span>
                                        </div>
                                        : <div className='d-flex flex-column'>
                                            <span className='text-header'>{messageIsPostJobObj.header}</span>
                                            <span className='text-desc'>{messageIsPostJobObj.desc}</span>
                                        </div>
                            }

                            <span className="text-close" onClick={reload}>ปิด</span>
                        </div>
                    </ModalBody>


                </ModalContent>
            </Modal>
        </>
    )


}


export default CompleteModal;