import React from 'react';
import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeComponent from './components/home/home';
import ApplyJobComponent from './components/apply-job/apply-job';
import RegisterCompanyComponent from './components/company/register/register-company';
import PostJobComponent from './components/company/postjob/post-job';
import JobListComponent from './components/job-list/job-list';
import CompanyListComponent from './components/company-list/company-list';
import ShareJobComponent from './components/share-job/share-job';
import CompleteComponent from './components/complete/complete';
function App() {
  return (
    <div className="App">
      <BrowserRouter>

        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/company/register" element={<RegisterCompanyComponent />} />
          <Route path="/company/register/complete" element={<CompleteComponent />} />
          <Route path="/company/post-job" element={<PostJobComponent />} />
          <Route path="/company/post-job/complete" element={<CompleteComponent />} />
          <Route path="/jobseeker/apply" element={<ApplyJobComponent />} />
          <Route path="/jobseeker/apply/complete" element={<CompleteComponent />} />
          <Route path="/admin/share" element={<ShareJobComponent />} />
          <Route path="/admin/job-list" element={<JobListComponent />} />
          <Route path="/admin/company-list" element={<CompanyListComponent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
