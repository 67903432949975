import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { JobListModel } from "../../model/job-list.model";
import ApiServices from "../../services/api";
import "./job-list.scss";



const JobListComponent = () => {
    let navigate = useNavigate();
    const [listObject, setListObject] = useState<JobListModel[]>();


    React.useEffect(() => {
        getJobList();
    }, [])


    const getJobList = () => {

        ApiServices().getJobList().then((res: any) => {
            console.log(res);
            setListObject(res);
        })
    }

    const clickToView = (jobId) => {
        navigate("/admin/share/?job_id=" + jobId, { replace: false })
    }

    return (

        <>
            <div className="job-list">
                <div className="bg-control">

                    <h1 className="header-text mx-auto">งานทั้งหมด จากโครงการ 2000 บาท</h1>
                    <div className="container grid-table">

                        {
                            listObject?.map((res: JobListModel, index: any) => {



                                return (
                                    <>
                                        <div className="item-control d-flex">
                                            <span className="text-desc my-auto mx-auto">{res.title}</span>

                                        </div>
                                        <div className="item-control d-flex">
                                            <span className="text-desc my-auto mx-auto">{res.companyName}</span>

                                        </div>
                                        <div className="item-control d-flex">
                                            <button className="button-view my-auto mx-auto" onClick={() => clickToView(res.uuid)}>ดู</button>

                                        </div>


                                    </>
                                )
                            })
                        }

                    </div>

                </div>

            </div>


        </>
    )
}


export default JobListComponent;