
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import React from 'react';
import DESKTOP_IMG from "../assets/images/install-metamask-modal.png";
import "./file-max-size.scss";
import Lottie from "lottie-react";
import LoadingGif from "../assets/json/fail.json";
const FileMaxSizeModal = ({ isOpen, onOpen, onClose, onCloseError}: any) => {
    const [size, setSize] = React.useState('xxl')

    const messageIsRegisterObj = {
        header: "เกิดข้อผิดพลาด!",
        desc: "รองรับไฟล์ .pdf, .doc, .docx, .jpg และต้องขนาดไม่เกิน 2MB"
    }

  

    React.useEffect(() => {
        console.log(messageIsRegisterObj.header);
    }, [])

    const reload = () => {

        window.location.reload();
    }

    return (

        <>

            <Modal closeOnOverlayClick={false} size={size} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg='blackAlpha.800'
                    backdropFilter='blur(10px)' />
                <ModalContent className="complete ">
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <ModalBody className="border-control">
                        <div className="container  d-flex flex-column loading-control my-auto">
                            <Lottie animationData={LoadingGif} loop={true} autoPlay={true} className="lottie-control" />
                            <div className='d-flex flex-column'>
                                        <span className='text-header'>{messageIsRegisterObj.header}</span>
                                        <span className='text-desc'>{messageIsRegisterObj.desc}</span>
                                    </div>
                          
                            <span className="text-close" onClick={onCloseError}>ปิด</span>
                        </div>
                    </ModalBody>


                </ModalContent>
            </Modal>
        </>
    )


}


export default FileMaxSizeModal;