import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CompanyListModel from "../../model/company-list.model";
import { JobListModel } from "../../model/job-list.model";
import ApiServices from "../../services/api";
import "./company-list.scss";



const CompanyListComponent = () => {
    let navigate = useNavigate();
    const [listObject, setListObject] = useState<CompanyListModel[]>();


    React.useEffect(() => {
        getCompanyList();
    }, [])


    const getCompanyList = () => {

        ApiServices().getCompanyList().then((res: any) => {
            console.log(res);
            setListObject(res);
        })
    }

    const clickToView = (url) => {
        window.open(url, "_blank");
    }

    return (

        <>
            <div className="company-list">
                <div className="bg-control">

                    <h1 className="header-text mx-auto">บริษัททั้งหมด จากโครงการ 2000 บาท</h1>
                    <div className="container grid-table">
                      

                        {
                            listObject?.map((res: CompanyListModel, index: any) => {



                                return (
                                    <>
                                        <div className="box-border d-flex flex-column">
                                            <div className="item-control">
                                                <img src={res.logo} className="logo-img mx-auto my-auto" ></img>
                                                <div className="d-flex flex-column">
                                                    <span className="text-header my-auto">ชื่อบริษัท</span>
                                                    <span className="text-desc my-auto">{res.name}</span>
                                                </div>


                                            </div>
                                            <div className="item-control">
                                                <button className="button-view my-auto mx-auto" onClick={() => clickToView(res.candidateSheet)}>ดู Candidate</button>
                                                <button className="button-view my-auto mx-auto" onClick={() => clickToView(res.employerCandidateSheet)}>Candidate หลัง Screen</button>
                                            </div>


                                        </div>


                                    </>
                                )
                            })
                        }

                    </div>

                </div>

            </div>


        </>
    )
}


export default CompanyListComponent;