
import "./post-job.scss";
import TOP_COVER from "../../../assets/images/cover-postjob.png"
import {
    getDownloadURL,
    ref,
    uploadBytesResumable
} from "firebase/storage";
import 'draft-js/dist/Draft.css';
import React, { useRef, useState } from "react";
import storage from "../../../services/firebase-config";
import { Crop } from "react-image-crop";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    centerCrop,
    makeAspectCrop,
    PixelCrop,
} from 'react-image-crop';
import { useDebounceEffect } from "../../../services/useDebounceEffect";
import { canvasPreview } from "../../../services/canvasPreview";
import 'react-image-crop/src/ReactCrop.scss'
import { useDisclosure } from "@chakra-ui/react";
import { Field, Form } from "react-final-form";
import GooglePlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import PlaceIdModel from "../../../model/place-id";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import ApiServices from "../../../services/api";
import LoadingMessage from "../../../modal/loading";
import CompleteModal from "../../../modal/complete";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import MyEditor from "../../../services/MyEditor";
import UtilityService from "../../../utils/utility";
import { useNavigate } from "react-router-dom";
function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 100,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

const openPositionArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 25, 30, 35, 40, 50];
const minExpArray = [0, 1, 2, 3, 4, 5];


const genderArray = [
    {
        id: "",
        value: "กรุณาเลือก"
    },
    {
        id: "ชาย",
        value: "ชาย"
    },
    {
        id: "หญิง",
        value: "หญิง"
    },
    {
        id: "ไม่ระบุ",
        value: "ไม่ระบุ"
    },
]
const jobRole = ['ขาย', 'งานในร้านอาหารและเครื่องดื่ม', 'ความสะอาดและสวน',
    'รักษาความปลอดภัย', 'แคชเชียร์', 'บัญชีและการเงิน', 'ธุรการ',
    'ช่าง', 'โรงงาน', 'คลังสินค้า', 'ขับรถ', 'ครัว', 'บริการลูกค้า',
    'ส่งสินค้า', 'จัดซื้อ', 'บริการความงาม', 'เลขานุการ', 'ก่อสร้าง',
    'ตัวแทนจำหน่าย', 'อีเวนท์']
const jobTypeArray = [{
    id: "",
    value: "กรุณาเลือก"
}, {
    id: "พาร์ทไทม์",
    value: "พาร์ทไทม์"
},
{
    id: "ประจำ",
    value: "ประจำ"
},
{
    id: "สัญญาจ้าง",
    value: "สัญญาจ้าง"
},
]

const eduArray = [
    {
        id: "",
        value: "กรุณาเลือก"
    },
    {
        id: "ต่ำกว่ามัธยมศึกษา",
        value: "ต่ำกว่ามัธยมศึกษา"
    },
    {
        id: "มัธยมศึกษาตอนต้น",
        value: "มัธยมศึกษาตอนต้น"
    },
    {
        id: "มัธยมศึกษาตอนปลาย",
        value: "มัธยมศึกษาตอนปลาย"
    },
    {
        id: "ปวช.",
        value: "ปวช."
    },
    {
        id: "ปวท.",
        value: "ปวท."
    },
    {
        id: "ปวส.",
        value: "ปวส."
    },
    {
        id: "อนุปริญญา",
        value: "อนุปริญญา"
    },
    {
        id: "ปริญญาตรี",
        value: "ปริญญาตรี"
    },
]
const containerStyle = {
    marginTop: '12px',
    width: '100%',
    height: '300px',
    marginLeft: 'auto',
    marginRight: 'auto'
};
const PostJobComponent = () => {
    let navigate = useNavigate();
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState<number | undefined>(16 / 9)
    const imgRef = useRef<HTMLImageElement>(null)
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const [file, setFile] = useState<File>();
    const [crop, setCrop] = useState<Crop>();
    const [isCrop, setIsCrop] = useState(false);
    const [imgSrc, setImgSrc] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isError, setIsError] = useState(false);
    const [percent, setPercent] = useState(0);
    let companyId = (new URLSearchParams(window.location.search)).get("companyId")
    const APIKEY = "AIzaSyAmrgjhlMNSUuelqFkLuYKwbock_o1TQiA";
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: APIKEY
    })
    const [map, setMap] = React.useState(null)
    const [editorState, setEditorState] = React.useState(
        EditorState.createEmpty()
    );

    const [editorStateBenefit, setEditorStateBenefit] = React.useState(
        EditorState.createEmpty()
    );



    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    const [value, setValue] = useState<PlaceIdModel>();
    const [center, setCenter] = useState<{ lat: number, lng: number }>();
    const [onLoad, setOnLoad] = useState();
    const [address, setAddress] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [questionArray, setQuestionArray] = useState([
        0
    ]);

    React.useEffect(() => {
        console.log(value);
        setCenter({ lat: 0, lng: 0 })

        if (value) {
            setAddress(value.value.description);
            geocodeByAddress(value.label)
                .then(results => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    setCenter({ lat: lat, lng: lng })



                    console.log('Successfully got latitude and longitude', { lat, lng })
                })

            geocodeByPlaceId(value.value.place_id)
                .then(results => console.log(results))
                .catch(error => console.error(error));
        }
    }, [value])
    React.useCallback(function callback(map) {
        if (center) {
            const bounds = new window.google.maps.LatLngBounds(center);
            map.fitBounds(bounds);
            setMap(map)
        }
    }, [])

    // Handle file upload event and update state
    function handleChange(event) {
        setFile(event.target.files[0]);
    }
    const handleUpload = (values) => {
        if (!file) {
            alert("Please upload an image first!");
        } else {
            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    setPercent(percent);
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        values.logo = url;
                        alert("Complete");
                        ApiServices().saveCompany(values).then((res: any) => {
                            window.location.reload();

                        })

                    });
                }
            );
        }


    };

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
            onOpen();
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result!.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    React.useEffect(() => {
        setAspect(1 / 1)
    }, [])

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined)
        } else if (imgRef.current) {
            const { width, height } = imgRef.current
            setAspect(1 / 1)
            setCrop(centerAspectCrop(width, height, 1 / 1))
        }
    }

    const clickCrop = () => {
        setIsCrop(true);
        onClose();
    }

    const addQuestion = () => {

        if (questionArray.length < 10) {
            setQuestionArray(questionArray => [...questionArray, questionArray[questionArray.length - 1]]);
        }


    }

    const deleteQuestion = (index, values) => {

        let data = [...questionArray];
        console.log(data);
        data.splice(index, 1);
        console.log(data);

        delete values["qid" + index];
        setQuestionArray(data);
    }

    const onEditorStateChange = (editorState) => {


        setEditorState(editorState)
    }

    const onEditorStateBenefitChange = (editorStateBenefit) => {


        setEditorStateBenefit(editorStateBenefit)
    }


    const onSubmit = async (values) => {
        setIsSubmit(true);
        console.log();
        if (values.jobTitle && values.openPosition && values.jobType && values.jobDescription && values.salaryType && values.jobRole
            && values.salaryFrom && values.salaryTo && values.benefit && values.minEXP && values.gender
            && values.education && values.ageTo && values.ageFrom && address) {

            if (!isError) {
                const jd = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                const benefit = draftToHtml(convertToRaw(editorStateBenefit.getCurrentContent()));
                values.address = address;
                values.companyId = companyId;
                values.addressPlaceName = address;
                values.addressLat = center?.lat;
                values.addressLon = center?.lng;
                values.openPosition = Number(values.openPosition)
                values.salaryFrom = Number(values.salaryFrom)
                values.salaryTo = Number(values.salaryTo)
                values.minEXP = Number(values.minEXP)
                values.ageTo = Number(values.ageTo)
                values.ageFrom = Number(values.ageFrom)
                values.benefit = benefit;
                values.jobDescription = jd
                var resValues = values;
                resValues.questions = []
                let countNum = 0;
                questionArray.map((item, index) => {
                  

                    if(values["qid" + index] !== undefined){
                        const strQuestion = values["qid" + index]
                       
                        resValues.questions[countNum] = strQuestion
                        console.log(resValues);
                        delete resValues["qid" + index];
                        countNum++;
                    }
                   
                    // question.push(strQuestion)
                })
                console.log(resValues);
                setIsLoading(true);
                onOpen();
                ApiServices().postJob(resValues).then((res: any) => {
                    UtilityService().clickSendEvent("Submit", "Post Job", "Post Job Success");
                    setIsLoading(false);
                    setIsComplete(true);
                    navigate('/company/post-job/complete?isPostJob=true')
                })
            }


        }
    }



    return (
        <>
            <div className="post-job d-flex flex-column">
                <div className="bg-control">

                    <div className="container container-control">

                        <img src={TOP_COVER} className="img-top-cover"></img>

                        <div className="form-control">
                            <span className="text-header"> กรอกรายละเอียดโพสต์งาน</span>
                            <div className="divider-gray"></div>


                            {/* <button onClick={handleUpload}>Upload to Firebase</button>
                            <p>{percent} "% done"</p> */}

                            <Form onSubmit={onSubmit}
                                validate={values => {
                                    const errors = {
                                        jobTitle: "", openPosition: "", jobType: "", jobRole: "",
                                        jobDescription: "", salaryType: "", salaryFrom: "", salaryTo: "",
                                        benefit: "", minEXP: "", gender: "", education: "",
                                        ageTo: "", ageFrom: ""
                                    }
                                    setIsError(false);
                                    if (!values.jobTitle) {
                                        setIsError(true);
                                        errors.jobTitle = 'กรุณากรอก'
                                    }
                                    if (!values.openPosition) {
                                        setIsError(true);
                                        errors.openPosition = 'กรุณาเลือก'
                                    }
                                    if (!values.jobType) {
                                        setIsError(true);
                                        errors.jobType = 'กรุณาเลือก'
                                    }
                                    if (!values.jobRole) {
                                        setIsError(true);
                                        errors.jobRole = 'กรุณาเลือก'
                                    }
                                    if (!values.jobDescription) {
                                        setIsError(true);
                                        errors.jobDescription = 'กรุณากรอก'
                                    }
                                    if (!values.salaryType) {
                                        setIsError(true);
                                        errors.salaryType = 'กรุณาเลือก'
                                    }

                                    if (Number(values.salaryFrom) == 0) {
                                        setIsError(true);
                                        errors.salaryFrom = 'ต้องไม่น้อยกว่า 1'
                                    }
                                    if (Number(values.salaryTo) == 0) {
                                        setIsError(true);
                                        errors.salaryFrom = 'ต้องไม่น้อยกว่า 1'
                                    }



                                    if (!values.salaryFrom) {
                                        setIsError(true);
                                        errors.salaryFrom = 'กรุณากรอก'
                                    }
                                    if (!values.salaryTo) {
                                        setIsError(true);
                                        errors.salaryTo = 'กรุณากรอก'
                                    }
                                    if (!values.benefit) {
                                        setIsError(true);
                                        errors.benefit = 'กรุณากรอก'
                                    }

                                    if (!values.minEXP) {
                                        setIsError(true);
                                        errors.minEXP = 'กรุณาเลือก'
                                    }
                                    if (!values.gender) {
                                        setIsError(true);
                                        errors.gender = 'กรุณาเลือก'
                                    }
                                    if (!values.education) {
                                        setIsError(true);
                                        errors.education = 'กรุณาเลือก'
                                    }
                                    if (!values.ageFrom) {
                                        setIsError(true);
                                        errors.ageFrom = 'กรุณากรอก'
                                    } if (!values.ageTo) {
                                        setIsError(true);
                                        errors.ageTo = 'กรุณากรอก'
                                    }
                                    if (values.salaryTo && values.salaryFrom) {
                                        if (Number(values.salaryTo) < Number(values.salaryFrom)) {
                                            setIsError(true);
                                            errors.salaryTo = 'รายได้สูงสุดต้องไม่น่ากว่า รายได้ต่ำสุด'
                                        }
                                    }

                                    if (Number(values.ageFrom) < 18) {
                                        setIsError(true);
                                        errors.ageFrom = 'อายุต้องไม่ต่ำกว่า 18'
                                    }
                                    if (Number(values.ageTo) < 18) {
                                        setIsError(true);
                                        errors.ageTo = 'อายุต้องไม่ต่ำกว่า 18'
                                    }
                                    if (Number(values.ageFrom) > 100) {
                                        setIsError(true);
                                        errors.ageFrom = 'อายุต้องไม่เกิน 100'
                                    }
                                    if (Number(values.ageTo) > 100) {
                                        setIsError(true);
                                        errors.ageTo = 'อายุต้องไม่เกิน 100'
                                    }

                                   

                                    return errors
                                }}
                                render={({ handleSubmit, form, submitting, pristine, values }) => (


                                    <form onSubmit={handleSubmit}>

                                        <div className="form-grid-control">
                                            <Field name="jobTitle">
                                                {({ input, meta }) => (
                                                    <div className="form-item-right">
                                                        <label className="text-header">ชื่อตำแหน่ง <span className="required-color">*</span></label>
                                                        <input {...input} type="text" className="input-form" placeholder="" />
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>


                                        </div>
                                        <div className="form-grid-control">
                                            <Field name="jobRole">
                                                {({ input, meta }) => (
                                                    <div className="form-item-right">
                                                        <label className="text-header">ประเภทงาน <span className="required-color">*</span></label>
                                                        <div className="select-control">
                                                            <Field name="jobRole" component="select"  >
                                                                <option value="">กรุณาเลือก</option>
                                                                {
                                                                    jobRole.map((res: any, index: any) => {

                                                                        return (
                                                                            <option value={res} key={index}>{res}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </Field>
                                                        </div>
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}

                                                    </div>
                                                )}
                                            </Field>


                                        </div>
                                        <div className="form-grid-control">
                                            <Field name="openPosition">
                                                {({ input, meta }) => (
                                                    <div className="form-item-left">
                                                        <label className="text-header">ตำแหน่งที่เปิดรับ <span className="required-color">*</span></label>
                                                        <div className="select-control">
                                                            <Field name="openPosition" component="select">
                                                                <option value="" >กรุณาเลือก</option>
                                                                {
                                                                    openPositionArray.map((res: any, index: any) => {

                                                                        return (
                                                                            <option value={res} key={index}>{res}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </Field>
                                                        </div>
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="form-grid-control">
                                            <Field name="jobType">
                                                {({ input, meta }) => (
                                                    <div className="form-item-left">
                                                        <label className="text-header">ประเภทการจ้าง <span className="required-color">*</span></label>
                                                        <div className="select-control">
                                                            <Field name="jobType" component="select">

                                                                {
                                                                    jobTypeArray.map((res: any, index: any) => {

                                                                        return (
                                                                            <option value={res.id} key={index}>{res.value}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </Field>
                                                        </div>
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="form-grid-control">
                                            <Field name="jobDescription">
                                                {({ input, meta }) => (
                                                    <div className="form-item-right">
                                                        <label className="text-header">รายละเอียดงาน <span className="required-color">*</span></label>

                                                        <Editor
                                                            {...input}
                                                            wrapperClassName="wrapper-class"
                                                            editorClassName="editor-class"
                                                            toolbarClassName="toolbar-class"
                                                            onEditorStateChange={onEditorStateChange}
                                                            toolbar={{
                                                                options: ['inline', 'fontSize', 'list'],
                                                                inline: {
                                                                    options: ['bold', 'italic', 'underline'],
                                                                    bold: { className: 'bordered-option-classname' },
                                                                    italic: { className: 'bordered-option-classname' },
                                                                    underline: { className: 'bordered-option-classname' }
                                                                }, fontSize: {
                                                                    className: 'bordered-option-classname',
                                                                },
                                                                list: {
                                                                    inDropdown: false,
                                                                    className: undefined,
                                                                    component: undefined,
                                                                    dropdownClassName: undefined,
                                                                    options: ['unordered', 'ordered', 'indent', 'outdent']

                                                                },

                                                            }}
                                                        />
                                                        {/* <textarea {...input} rows={5} className="teatarea-form" placeholder="" /> */}
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>


                                        </div>

                                        <div className="form-grid-control">
                                            <Field name="salaryType" >
                                                {({ input, meta }) => (
                                                    <div className="form-item-right">
                                                        <label className="text-header">ค่าตอบแทนและสวัสดิการ <span className="required-color">*</span>

                                                        </label>
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                        <div className="d-flex radio-control">
                                                            <input  {...input} type="radio" value="รายวัน" />

                                                            <span className="radio-text">รายวัน</span>
                                                        </div>
                                                        <div className="d-flex radio-control">
                                                            <input {...input} className="radio-text" type="radio" value="รายเดือน" />
                                                            <span className="radio-text">รายเดือน</span>
                                                        </div>

                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="form-grid-control">
                                            <label className="text-header">เงินเดือน<span className="required-color">*</span></label>
                                            <div className="salary-grid-control">
                                                <Field name="salaryFrom">
                                                    {({ input, meta }) => (
                                                        <div className="form-item-right">
                                                            <input {...input} type="number" className="input-form" placeholder="เริ่มต้น" />
                                                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                                <div className="form-item-right">
                                                    <label className="text-header my-auto mx-auto"> ถึง </label>
                                                </div>
                                                <Field name="salaryTo">
                                                    {({ input, meta }) => (
                                                        <div className="form-item-right">
                                                            <input {...input} type="number" className="input-form" placeholder="สิ้นสุด" />
                                                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>

                                        </div>
                                        <div className="form-grid-control">
                                            <Field name="benefit">
                                                {({ input, meta }) => (
                                                    <div className="form-item-right">
                                                        <label className="text-header">สวัสดิการ <span className="required-color">*</span></label>
                                                        <Editor
                                                            {...input}
                                                            wrapperClassName="wrapper-class"
                                                            editorClassName="editor-class"
                                                            toolbarClassName="toolbar-class"
                                                            onEditorStateChange={onEditorStateBenefitChange}
                                                            toolbar={{
                                                                options: ['inline', 'fontSize', 'list'],
                                                                inline: {
                                                                    options: ['bold', 'italic', 'underline'],
                                                                    bold: { className: 'bordered-option-classname' },
                                                                    italic: { className: 'bordered-option-classname' },
                                                                    underline: { className: 'bordered-option-classname' }
                                                                }, fontSize: {
                                                                    className: 'bordered-option-classname',
                                                                },
                                                                list: {
                                                                    inDropdown: false,
                                                                    className: undefined,
                                                                    component: undefined,
                                                                    dropdownClassName: undefined,
                                                                    options: ['unordered', 'ordered', 'indent', 'outdent']

                                                                },

                                                            }}
                                                        />
                                                        {/* <textarea {...input} rows={5} className="teatarea-form" placeholder="" /> */}
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>


                                        </div>
                                        <div className="form-grid-control">
                                            <div className="form-item-left">
                                                <label className="text-header">แผนที่ทำงาน<span className="required-color">*</span></label>
                                                {isSubmit && !address && <span className="error-text">กรุณากรอก</span>}

                                                {
                                                    isLoaded && center ?

                                                        <div className="d-flex flex-column">
                                                            <GooglePlacesAutocomplete

                                                                selectProps={{
                                                                    value,
                                                                    onChange: setValue,
                                                                }}
                                                            />
                                                            <GoogleMap
                                                                mapContainerStyle={containerStyle}
                                                                center={center}
                                                                zoom={15}
                                                                onLoad={onLoad}
                                                                onUnmount={onUnmount}
                                                            >
                                                                { /* Child components, such as markers, info windows, etc. */}
                                                                <MarkerF position={center}></MarkerF>
                                                            </GoogleMap>


                                                        </div> : null
                                                }
                                            </div>

                                        </div>
                                        <div className="form-grid-control">
                                            <Field name="minEXP">
                                                {({ input, meta }) => (
                                                    <div className="form-item-left">
                                                        <label className="text-header">ประสบการณ์ขั้นต่ำ <span className="required-color">*</span></label>
                                                        <div className="select-control">
                                                            <Field name="minEXP" component="select">
                                                                <option value="" >กรุณาเลือก</option>
                                                                {
                                                                    minExpArray.map((res: any, index: any) => {

                                                                        return (
                                                                            <option value={res} key={index}>{res}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </Field>
                                                        </div>
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="form-grid-control">
                                            <Field name="gender">
                                                {({ input, meta }) => (
                                                    <div className="form-item-left">
                                                        <label className="text-header">เพศ <span className="required-color">*</span></label>
                                                        <div className="select-control">
                                                            <Field name="gender" component="select">
                                                                {
                                                                    genderArray.map((res: any, index: any) => {

                                                                        return (
                                                                            <option value={res.id} key={index}>{res.value}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </Field>
                                                        </div>
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="form-grid-control">
                                            <Field name="education">
                                                {({ input, meta }) => (
                                                    <div className="form-item-left">
                                                        <label className="text-header">การศึกษา <span className="required-color">*</span></label>
                                                        <div className="select-control">
                                                            <Field name="education" component="select">

                                                                {
                                                                    eduArray.map((res: any, index: any) => {

                                                                        return (
                                                                            <option value={res.id} key={index}>{res.value}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </Field>
                                                        </div>
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="form-grid-control">
                                            <label className="text-header">อายุ<span className="required-color">*</span></label>
                                            <div className="salary-grid-control">
                                                <Field name="ageFrom">
                                                    {({ input, meta }) => (
                                                        <div className="form-item-right">
                                                            <input {...input} type="number" className="input-form" placeholder="ขั้นต่ำ" />
                                                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                                <div className="form-item-right">
                                                    <label className="text-header my-auto mx-auto"> ถึง </label>
                                                </div>
                                                <Field name="ageTo">
                                                    {({ input, meta }) => (
                                                        <div className="form-item-right">
                                                            <input {...input} type="number" className="input-form" placeholder="สูงสุด" />
                                                            {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>

                                        </div>
                                        <div className="pre-screen-control d-flex flex-column">


                                            <span className="text-header"> คำถามสำหรับผู้สมัครงาน (Pre-screening Questions) สูงสุด 10 ข้อ</span>
                                            <div className="divider-gray"></div>
                                            {
                                                questionArray.map((res: any, index: any) => {

                                                    return (

                                                        index === questionArray.length - 1 && index > 0 ?

                                                            <Field name={"qid" + index} key={index}>
                                                                {({ input, meta }) => (
                                                                    <div className="form-grid-control" key={index}>
                                                                        <div className="form-item-right">
                                                                            <label className="text-header">คำถามที่ {index + 1} <span className="required-color"></span></label>
                                                                            <div className="form-item-control">
                                                                                <input {...input} type="text"
                                                                                    className="input-form" placeholder="" />
                                                                                <span className="delete-question" onClick={() => deleteQuestion(index, values)}>ลบ</span>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Field> :
                                                            <Field name={"qid" + index} key={index}>
                                                                {({ input, meta }) => (
                                                                    <div className="form-grid-control" key={index}>
                                                                        <div className="form-item-right">
                                                                            <label className="text-header">คำถามที่ {index + 1} <span className="required-color"></span></label>
                                                                            <input {...input} type="text"
                                                                                className="input-form" placeholder="" />

                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Field>


                                                    )
                                                })
                                            }
                                            {
                                                questionArray.length < 10 ?
                                                    <span className="add-question" onClick={addQuestion}>เพิ่มคำถาม</span> : null
                                            }

                                        </div>

                                        <button className="button-submit" type="submit" onClick={() => onSubmit(values)}>ส่งข้อมูล</button>

                                    </form>

                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading ? <LoadingMessage isOpen={isOpen} onClose={onClose}></LoadingMessage> :

                    isComplete && !isLoading ? <CompleteModal isOpen={isOpen} onClose={onClose} isPostJob={true}></CompleteModal> : null
            }


        </>
    )
}

export default PostJobComponent;