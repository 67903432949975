import Lottie from "lottie-react";
import LoadingGif from "../../assets/json/complete.json";

import './complete.scss';


const CompleteComponent = () => {
    let isApply = (new URLSearchParams(window.location.search)).get("isApply")
    let isRegister = (new URLSearchParams(window.location.search)).get("isRegister")
    const messageIsRegisterObj = {
        header: "ลงทะเบียนบริษัทสำเร็จ!",
        desc: "ระบบจะทำการตรวจสอบข้อมูลภายใน 6 ชั่วโมง โดยคุณจะสามารถเริ่มโพสต์งานได้ เมื่อได้รับการแจ้งเตือนนะคะ"
    }

    const messageIsPostJobObj = {
        header: "ขอบคุณสำหรับข้อมูลตำแหน่งงานที่ต้องการเปิดรับนะคะ",
        desc: "เราจะรีบดำเนินการค้นหาผู้สมัครที่ใช่ให้คุณ คุณจะได้รับการแจ้งเตือนทันที เมื่อมีผู้สมัครที่ตรงตามคุณสมบัติที่คุณต้องการ"
    }
    const messageIsApplyObj = {
        header: "สมัครงานสำเร็จ!",
        desc: "หากผู้ว่าจ้างสนใจโปรไฟล์ของคุณ คุณจะได้รับการติดต่อกลับทางเบอร์โทรศัพท์ที่ระบุไว้"
    }



    return (

        <>

            <div className="complete-comp d-flex">
                <div className="bg-control">
                    <div className="container  d-flex flex-column loading-control my-auto mx-auto">
                        <Lottie animationData={LoadingGif} loop={true} autoPlay={true} className="lottie-control" />

                        {
                            isRegister ?
                                <div className='d-flex flex-column'>
                                    <span className='text-header'>{messageIsRegisterObj.header}</span>
                                    <span className='text-desc'>{messageIsRegisterObj.desc}</span>
                                </div> :
                                isApply ?
                                    <div className='d-flex flex-column'>
                                        <span className='text-header'>{messageIsApplyObj.header}</span>
                                        <span className='text-desc'>{messageIsApplyObj.desc}</span>
                                    </div>
                                    : <div className='d-flex flex-column'>
                                        <span className='text-header'>{messageIsPostJobObj.header}</span>
                                        <span className='text-desc'>{messageIsPostJobObj.desc}</span>
                                    </div>
                        }

                    </div>
                </div>
            </div>

        </>
    )
}

export default CompleteComponent;