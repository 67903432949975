import React from "react";
import "./home.scss";

const HomeComponent = () => {
    

    React.useEffect(() => {

        window.open("https://job.jobnow.work", "_self");

    })

    return (
        <></>
    )
}

export default HomeComponent;