const test = {
    ga: "",
    ga_4: "G-Q0F2JHY4K3",
    link: {
        apply: "https://easy-jobnow-test.web.app/jobseeker/apply?job_id=",
        url: "https://easy-jobnow-test.web.app/",
        register_company: "https://easy-jobnow-test.web.app/company/register",
        jobnow: "https://d27q87hxpexpp4.cloudfront.net/jobs"
    },
    api: {
        miniprofile: "https://asia-southeast1-scoutout-miniprofile-test.cloudfunctions.net/",
        jobnow: "https://messuadev.scoutout.net/api/v3.0/jobnow/job-seeker/public/jobs/",
        register_company: "companies",
        post_job: "jobs",
        apply_job: "jobs/",
        list_job: "jobs/",
        list_company: "companies/"
    },
    sheets: {
        app_sheets_id: "1xtfEfLxG5zc4Q8bOH9TdDYMZLEQxU367n1L093qT4ZA",
        sheets_id: "112146789",
        type: "service_account",
        project_id: "scoutout-jobnow-chatbot-spdhxm",
        private_key_id: "35861b02d82a96cdcdcddbaa0b24b8408351c326",
        private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDzRL6TgR1ieC84\nV08F31PA17pK6TH8bjuIySLnZAu0QGzxo59SrGL4bt9oMZF1jqP3unR5XbYQRuhj\n9gvI2wjgPIP4zKk/+W3+MXTJQGCn2fZFQhHNNoYAAhXTkwIOZCrAigV1bNC+2k27\n5lKn/0Ek2A4cl2+kdn5jrCFuJIdqr4L+pnFq6Hv1A7mAmWK1+uxyUlMJML3FYzGo\n7/y8ZULgjqDCFiRRyPgSiqkreVJLxl4vOPUAs93I2ZQH15d5t9u6VKMLwVZKGZFM\nfH8ajqfaK6bKEhTHqLQlLlDmuU0k3Y59X566fyyp1oXk4QeSEHbJYQYYcMSqqAao\n5UMaKYd/AgMBAAECggEABgMX6/tP55ltc/4Bukps/ndrvOLgf7WVtmlfecp+Ljk9\nYrIW2Os2aEoNLr0INEM53YB31RwHc4WHxBirl+OEkk4h5ingsO+609e9uXo5fqHO\nCqN5tHxOztHpCi6NlB948wM2OVMJdv4G18hMbfx1TAd5lHlQTVyO/mry9I7l4zFg\n5wknocgsB773s3Ivg5E2itbK1NJGF8bYX2Xj2DLMiFrF4P8f4HaW0IkhvJcUGbi1\nJCe+39ZDlMNcnbP84FpbR+RQCJ6wDUvqzs0P1X15TkXcDNjS/mp+yp4j2YfQapqT\nJz5DOHcdWus+sjdd6EZgXRhADEd7IpyvKN0NaL9i0QKBgQD84H6lbT/uvT4aJ+me\nO/4MzJVOOciAL7XU/QvFni8RiK3rt03pli1BSBnq17lcKOwqDdj6ztIsVyBJnuMZ\n7YcYFYdYOmtHRD08gU+BwlDulPH9jlQv0KUOYzyQrfhdV09WPAQmzbZVc7iYLv+j\nao+Q2xP5TWJmP5y42RGXwtJT+QKBgQD2Rd8XIXaHpWlMkH+r9spvoRoPq/xajci8\nJvn9JlQ2o+hcxvmdsGr9DcgALPMdk6FTIZqBqcXBnU3N9L2AE0rmzG7kJNWcK6Qm\n1/JAAqF9dZ4Hbd2i6WWOt1AJgcbLEU+P5oOYKLAbVCiGy4Y5sYxIYPQYCXUy1ROP\nG7amKCqlNwKBgEpF4czS3tFPUhs6l9p7Am5d9IiKXkKQQNbzD+8WO/rpazPtnfu6\nuUYQWFRq0i2oc10SviVEc+TEHl6hRVDLPD+tqxgV6IaBG39W97RED6NTqhPwJvf4\nT1J/gT15a5Z2232XmRyQlTpaB+DjBQDJfqtsPVyfBwTDMFP2pb0uYrp5AoGAZte2\n2gn3XZ4N4xNUvZNJ5mP4ZiMENyyOb0Q3kSe4czjviA7vnpJVm2dt5eTfgbJs5DJc\nO1mSY9A09ZUGrb1gwhENHejj61uuKSp015ZBqgq7+449yBJ/fqG+orm3MoYAG6+/\ntIMJR+BVLDVB0EX8tx0F0iPg9WFV0aVZGPodFPECgYA1LTi4/FXIrqafFGZfnk0/\nSz/fLosQSOfDkWpZSeweFhxflL4ErVfzJ+eOxEHH17FnC/NmDdNHnSwOxKoInoYg\nIfZjadFYLJ1QhC45iUFRDoHOQcVyqJpIADjH53LX78LORcERLfEjrHv6S9Vz2Uu0\nq5ktauaKpqAUpbaGztQHcQ==\n-----END PRIVATE KEY-----\n",
        client_email: "google-sheets-easy-jobnow@scoutout-jobnow-chatbot-spdhxm.iam.gserviceaccount.com",
        client_id: "110640022934937646645",
        auth_uri: "https://accounts.google.com/o/oauth2/auth",
        token_uri: "https://oauth2.googleapis.com/token",
        auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
        client_x509_cert_url: "https://www.googleapis.com/robot/v1/metadata/x509/google-sheets-easy-jobnow%40scoutout-jobnow-chatbot-spdhxm.iam.gserviceaccount.com"

    }

};


const prod = {
    ga: "",
    ga_4: "G-1XV6EJDLYT",
    link: {
        apply: "https://easy.jobnow.work/jobseeker/apply?job_id=",
        url: "https://easy.jobnow.work/",
        register_company: "https://easy.jobnow.work/company/register",
        jobnow: "https://job.jobnow.work/jobs"
    },
    api: {
        miniprofile: "https://asia-southeast1-scoutout-jobnow-chatbot-spdhxm.cloudfunctions.net/",
        jobnow: "https://jobnow-api.scoutoutapi.com/api/v3.0/jobnow/job-seeker/public/jobs/",
        register_company: "companies",
        post_job: "jobs",
        apply_job: "jobs/",
        list_job: "jobs/",
        list_company: "companies/"
    },
    sheets: {
        app_sheets_id: "1xtfEfLxG5zc4Q8bOH9TdDYMZLEQxU367n1L093qT4ZA",
        sheets_id: "112146789",
        type: "service_account",
        project_id: "scoutout-jobnow-chatbot-spdhxm",
        private_key_id: "35861b02d82a96cdcdcddbaa0b24b8408351c326",
        private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDzRL6TgR1ieC84\nV08F31PA17pK6TH8bjuIySLnZAu0QGzxo59SrGL4bt9oMZF1jqP3unR5XbYQRuhj\n9gvI2wjgPIP4zKk/+W3+MXTJQGCn2fZFQhHNNoYAAhXTkwIOZCrAigV1bNC+2k27\n5lKn/0Ek2A4cl2+kdn5jrCFuJIdqr4L+pnFq6Hv1A7mAmWK1+uxyUlMJML3FYzGo\n7/y8ZULgjqDCFiRRyPgSiqkreVJLxl4vOPUAs93I2ZQH15d5t9u6VKMLwVZKGZFM\nfH8ajqfaK6bKEhTHqLQlLlDmuU0k3Y59X566fyyp1oXk4QeSEHbJYQYYcMSqqAao\n5UMaKYd/AgMBAAECggEABgMX6/tP55ltc/4Bukps/ndrvOLgf7WVtmlfecp+Ljk9\nYrIW2Os2aEoNLr0INEM53YB31RwHc4WHxBirl+OEkk4h5ingsO+609e9uXo5fqHO\nCqN5tHxOztHpCi6NlB948wM2OVMJdv4G18hMbfx1TAd5lHlQTVyO/mry9I7l4zFg\n5wknocgsB773s3Ivg5E2itbK1NJGF8bYX2Xj2DLMiFrF4P8f4HaW0IkhvJcUGbi1\nJCe+39ZDlMNcnbP84FpbR+RQCJ6wDUvqzs0P1X15TkXcDNjS/mp+yp4j2YfQapqT\nJz5DOHcdWus+sjdd6EZgXRhADEd7IpyvKN0NaL9i0QKBgQD84H6lbT/uvT4aJ+me\nO/4MzJVOOciAL7XU/QvFni8RiK3rt03pli1BSBnq17lcKOwqDdj6ztIsVyBJnuMZ\n7YcYFYdYOmtHRD08gU+BwlDulPH9jlQv0KUOYzyQrfhdV09WPAQmzbZVc7iYLv+j\nao+Q2xP5TWJmP5y42RGXwtJT+QKBgQD2Rd8XIXaHpWlMkH+r9spvoRoPq/xajci8\nJvn9JlQ2o+hcxvmdsGr9DcgALPMdk6FTIZqBqcXBnU3N9L2AE0rmzG7kJNWcK6Qm\n1/JAAqF9dZ4Hbd2i6WWOt1AJgcbLEU+P5oOYKLAbVCiGy4Y5sYxIYPQYCXUy1ROP\nG7amKCqlNwKBgEpF4czS3tFPUhs6l9p7Am5d9IiKXkKQQNbzD+8WO/rpazPtnfu6\nuUYQWFRq0i2oc10SviVEc+TEHl6hRVDLPD+tqxgV6IaBG39W97RED6NTqhPwJvf4\nT1J/gT15a5Z2232XmRyQlTpaB+DjBQDJfqtsPVyfBwTDMFP2pb0uYrp5AoGAZte2\n2gn3XZ4N4xNUvZNJ5mP4ZiMENyyOb0Q3kSe4czjviA7vnpJVm2dt5eTfgbJs5DJc\nO1mSY9A09ZUGrb1gwhENHejj61uuKSp015ZBqgq7+449yBJ/fqG+orm3MoYAG6+/\ntIMJR+BVLDVB0EX8tx0F0iPg9WFV0aVZGPodFPECgYA1LTi4/FXIrqafFGZfnk0/\nSz/fLosQSOfDkWpZSeweFhxflL4ErVfzJ+eOxEHH17FnC/NmDdNHnSwOxKoInoYg\nIfZjadFYLJ1QhC45iUFRDoHOQcVyqJpIADjH53LX78LORcERLfEjrHv6S9Vz2Uu0\nq5ktauaKpqAUpbaGztQHcQ==\n-----END PRIVATE KEY-----\n",
        client_email: "google-sheets-easy-jobnow@scoutout-jobnow-chatbot-spdhxm.iam.gserviceaccount.com",
        client_id: "110640022934937646645",
        auth_uri: "https://accounts.google.com/o/oauth2/auth",
        token_uri: "https://oauth2.googleapis.com/token",
        auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
        client_x509_cert_url: "https://www.googleapis.com/robot/v1/metadata/x509/google-sheets-easy-jobnow%40scoutout-jobnow-chatbot-spdhxm.iam.gserviceaccount.com"

    }
};

const config = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? prod
    : test;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};