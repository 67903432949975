import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
 
// Initialize Firebase
const app = initializeApp ({
    apiKey: "AIzaSyAmrgjhlMNSUuelqFkLuYKwbock_o1TQiA",
    authDomain: "scoutout-jobnow-chatbot-spdhxm.firebaseapp.com",
    databaseURL: "https://scoutout-jobnow-chatbot-spdhxm.firebaseio.com",
    projectId: "scoutout-jobnow-chatbot-spdhxm",
    storageBucket: "scoutout-jobnow-chatbot-spdhxm.appspot.com",
    messagingSenderId: "960305438749",
    appId: "1:960305438749:web:eeb52e498c5491f473a6f4"
});
 
const storage = getStorage(app);
export default storage;