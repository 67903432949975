
import "./register-company.scss";
import TOP_COVER from "../../../assets/images/cover-register.png"
import {
    getDownloadURL,
    ref,
    uploadBytesResumable
} from "firebase/storage";

import React, { useRef, useState } from "react";
import storage from "../../../services/firebase-config";
import { Crop } from "react-image-crop";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    PixelCrop,
} from 'react-image-crop';
import { useDebounceEffect } from "../../../services/useDebounceEffect";
import { canvasPreview } from "../../../services/canvasPreview";
import 'react-image-crop/src/ReactCrop.scss'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { Field, Form } from "react-final-form";
import ApiServices from "../../../services/api";
import LoadingMessage from "../../../modal/loading";
import CompleteModal from "../../../modal/complete";
import imageCompression from "browser-image-compression";
import UtilityService from "../../../utils/utility";
import { useNavigate } from "react-router-dom";
function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 100,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

const RegisterCompanyComponent = () => {
    let navigate = useNavigate();
    const [imgPreview, setImgPreview] = useState("")
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState<number | undefined>(16 / 9)
    const imgRef = useRef<HTMLImageElement>(null)
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const [file, setFile] = useState<File>();
    const [crop, setCrop] = useState<Crop>();
    const [isCrop, setIsCrop] = useState(false);
    const [imgSrc, setImgSrc] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [percent, setPercent] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isError , setIsError] =  useState(false);


    // Handle file upload event and update state
    function handleChange(event) {
        setFile(event.target.files[0]);
    }
    const handleUpload = (values) => {
        if (!file) {
            alert("Please upload an image first!");
        } else {
            var currentTimeInSeconds = Math.floor(Date.now() / 1000);
            const storageRef = ref(storage, `/files/${currentTimeInSeconds}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    setPercent(percent);
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        const resValue = values;
                        resValue.logo = url;
                        ApiServices().saveCompany(resValue).then((res: any) => {

                            UtilityService().clickSendEvent("Submit", "Register Company" , "Register Success");
                            setIsComplete(true);
                            setIsLoading(false);
                            navigate('/company/register/complete?isRegister=true')
                        })

                    });
                }
            );
        }


    };

    const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const imageFile = e.target.files[0];
            console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
            console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

            const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1000,
                useWebWorker: true
            }
            try {
                const compressedFile = await imageCompression(imageFile, options);
                console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

                setFile(compressedFile);
                onOpen();
                setCrop(undefined) // Makes crop preview update between images.
                const reader = new FileReader()
                reader.addEventListener('load', () =>
                    setImgSrc(reader.result!.toString() || ''),
                )
                reader.readAsDataURL(compressedFile)


            } catch (error) {
                console.log(error);
            }


        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
            console.log(e.currentTarget)
        }
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    React.useEffect(() => {
        setAspect(1 / 1)

    }, [])

    function handleToggleAspectClick() {
        if (aspect) {
            setAspect(undefined)
        } else if (imgRef.current) {
            const { width, height } = imgRef.current
            setAspect(1 / 1)
            setCrop(centerAspectCrop(width, height, 1 / 1))
        }
    }

    const clickCrop = () => {
        setIsCrop(true);
        onClose();
    }

    const onSubmit = async (values) => {
        console.log(crop);
        console.log(file);
        setIsSubmit(true);
        if (values.isCorporate && values.name && values.tel && values.email && values.taxId && file) {
            if(!isError){
                setIsLoading(true);
                onOpen();
                if (values.isCorporate === "TRUE") {
                    values.isCorporate = true;
                } else {
                    values.isCorporate = false;
                }
                handleUpload(values);
            }
           
        }
    }

    return (
        <>
            <div className="register-company d-flex flex-column">
                <div className="bg-control">

                    <div className="container container-control">

                        <img src={TOP_COVER} className="img-top-cover"></img>

                        <div className="form-control">
                            <span className="text-header"> กรอกรายละเอียดบริษัท</span>
                            <div className="divider-gray"></div>


                            {/* <button onClick={handleUpload}>Upload to Firebase</button>
                            <p>{percent} "% done"</p> */}

                            <Form onSubmit={onSubmit}
                                validate={values => {
                                    const errors = {
                                       name: "", isCorporate: "",
                                        tel: "", email: "", taxId: ""
                                    }
                                    setIsError(false);
                                   
                                    if (!values.name) {
                                        setIsError(true);
                                        errors.name = 'กรุณากรอก'
                                    }
                                    if (!values.isCorporate) {
                                        setIsError(true);
                                        errors.isCorporate = 'กรุณาเลือก'
                                    }

                                    if (!values.tel) {
                                        setIsError(true);
                                        errors.tel = 'กรุณากรอก'
                                    } else {
                                        
                                        if (values.tel.toString().length < 9 || values.tel.toString().length > 13) {
                                            setIsError(true);
                                            errors.tel = 'เลขไม่ถูกต้อง'
                                        }
                                    }
                                    if (!values.email) {
                                        setIsError(true);
                                        errors.email = 'กรุณากรอก'
                                    } else {
                                        var emailReg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                                        if (!emailReg.test(values.email)) {
                                            setIsError(true);
                                            errors.email = 'อีเมล ผิดรูปแบบ ตัวอย่าง. abc@test.com'
                                        }
                                    }

                                    if (!values.taxId) {

                                        setIsError(true);
                                        errors.taxId = 'กรุณากรอก'
                                    } else {
                                        if (values.taxId.toString().length < 13 || values.taxId.toString().length > 13) {
                                            setIsError(true);
                                            errors.taxId = 'เลขไม่ถูกต้อง'
                                        }
                                    }




                                    return errors
                                }}
                                render={({ handleSubmit, form, submitting, pristine, values }) => (


                                    <form onSubmit={handleSubmit}>

                                        {
                                            !isComplete &&

                                            <div className="d-flex">
                                                <Field name="logo">
                                                    {({ input, meta }) => (
                                                        <div className="form-item-left d-flex flex-column">
                                                            <label className="text-header">อัปโหลดโลโก้บริษัท <span className="required-color">*</span></label>
                                                            {Boolean(completedCrop) && (
                                                                <canvas
                                                                    ref={previewCanvasRef}
                                                                    className="img-crop-complete"
                                                                />


                                                            )}
                                                            <input type="file" {...input} className="choose-file" accept="image/*" onChange={onSelectFile} />

                                                            {isSubmit && file === undefined && <span className="error-text">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>

                                            </div>
                                        }
                                        <div className="form-grid-control">
                                            <Field name="name">
                                                {({ input, meta }) => (
                                                    <div className="form-item-right">
                                                        <label className="text-header">ชื่อบริษัท <span className="required-color">*</span></label>
                                                        <input {...input} type="text" className="input-form" placeholder="" />
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>


                                        </div>

                                        <div className="form-grid-control">
                                            <Field name="isCorporate" >
                                                {({ input, meta }) => (
                                                    <div className="form-item-right">
                                                        <label className="text-header">ประเภทบริษัท <span className="required-color">*</span>

                                                        </label>
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                        <div className="d-flex radio-control">
                                                            <input  {...input} type="radio" value="TRUE" />

                                                            <span className="radio-text">นิติบุคคล</span>
                                                        </div>
                                                        <div className="d-flex radio-control">
                                                            <input {...input} className="radio-text" type="radio" value="FALSE" />
                                                            <span className="radio-text">บุคคลธรรมดา</span>
                                                        </div>

                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="form-grid-control">
                                            <Field name="taxId">
                                                {({ input, meta }) => (
                                                    <div className="form-item-right">
                                                        <label className="text-header">เลขประจำตัวผู้เสียภาษี / เลขประจำตัวประชาชน <span className="required-color">*</span></label>
                                                        <input {...input} type="number" className="input-form" placeholder="" maxLength={13} />
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>

                                                        }
                                                    </div>
                                                )}
                                            </Field>


                                        </div>
                                        <div className="form-grid-control">
                                            <Field name="tel">
                                                {({ input, meta }) => (
                                                    <div className="form-item-right">
                                                        <label className="text-header">เบอร์โทรศัพท์ <span className="required-color">*</span></label>
                                                        <input {...input} type="number" className="input-form" placeholder="" maxLength={12} />
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>


                                        </div>
                                        <div className="form-grid-control">
                                            <Field name="email">
                                                {({ input, meta }) => (
                                                    <div className="form-item-right">
                                                        <label className="text-header">อีเมล <span className="required-color">*</span></label>
                                                        <input {...input} type="text" className="input-form" placeholder="" />
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>


                                        </div>




                                        <button className="button-submit" type="submit" onClick={() => onSubmit(values)}>ส่งข้อมูล</button>

                                    </form>

                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {
                isLoading ? <LoadingMessage isOpen={isOpen} onClose={onClose} ></LoadingMessage> :

                    isComplete && !isLoading ? <CompleteModal isOpen={isOpen} onClose={onClose} isRegister={true}></CompleteModal> :
                        <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>เลือกตำแหน่งของ Logo บริษัท</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <ReactCrop
                                        crop={crop}
                                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                                        onComplete={(c) => setCompletedCrop(c)}
                                        aspect={aspect}
                                        circularCrop={true}
                                    >
                                        <img
                                            ref={imgRef}
                                            alt="Crop me"
                                            className="img-crop"
                                            src={imgSrc}
                                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                            onLoad={onImageLoad}
                                        />

                                    </ReactCrop>
                                    <button className="button-crop-submit" onClick={clickCrop}>ตกลง</button>
                                </ModalBody>


                            </ModalContent>
                        </Modal>

            }





        </>
    )
}

export default RegisterCompanyComponent;