import { Field, Form } from "react-final-form";
import "./apply-job.scss";
import TOP_COVER from "../../assets/images/apply/top-cover.png"
import React, { useRef, useState } from "react";
import JobDetailModel from "../../model/job-model";
import ApiServices from "../../services/api";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import HeaderComponent from "../header/header";
import { ApplyJobModel } from "../../model/apply-job-model";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import LoadingMessage from "../../modal/loading";
import CompleteModal from "../../modal/complete";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import storage from "../../services/firebase-config";
import FileMaxSizeModal from "../../modal/file-max-size";
import UtilityService from "../../utils/utility";
import imageCompression from "browser-image-compression";
import { Crop } from "react-image-crop";
import * as DOMPurify from 'dompurify';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    PixelCrop,
} from 'react-image-crop';
import { canvasPreview } from "../../services/canvasPreview";
import { useDebounceEffect } from "../../services/useDebounceEffect";
import { useNavigate } from "react-router-dom";
function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 100,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}
const containerStyle = {
    marginTop: '12px',
    width: '100%',
    height: '300px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '12pxasfd'
};
const ApplyJobComponent = () => {
    let navigate = useNavigate();
    const [isCrop, setIsCrop] = useState(false);
    let job_id = (new URLSearchParams(window.location.search)).get("job_id")
    let appName = (new URLSearchParams(window.location.search)).get("app")
    const [jobDetailModel, setJobDetailModel] = useState<JobDetailModel>();
    const [benefit, setBenefit] = useState<String[]>([]);
    const [center, setCenter] = useState<{ lat: number, lng: number }>();
    const [map, setMap] = React.useState(null)
    const [file, setFile] = useState<File>();
    const [fileImg, setFileImg] = useState<File>();
    const [crop, setCrop] = useState<Crop>();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isError, setIsError] = useState(false);
    const [isMaxSizeError, setIsMaxSizeError] = useState(false);
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    const [onLoad, setOnLoad] = useState();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [imgSrc, setImgSrc] = useState('');
    const [aspect, setAspect] = useState<number | undefined>(16 / 9)
    React.useEffect(() => {
        setAspect(1 / 1)
        console.log(appName);
        if (appName === "jobnow") {
            UtilityService().clickSendEvent("view", "Apply Job", "From JobNOW");
        } else {
            UtilityService().clickSendEvent("view", "Apply Job", "From Direct");
        }
        setCenter({ lat: 0, lng: 0 })
        getJobDetail();

    }, [])
    const benefitsArray = [
        {
            id: "EDUCATION_ALLOWANCE",
            value: "ทุนเพื่อการศึกษา"
        },
        {
            id: "FLEXIBLE_WORKING_HOUR",
            value: "เวลาการทำงานที่ยืดหยุ่น"
        },
        {
            id: "FREE_SHUTTLE_BUS",
            value: "บริการรถรับส่งพนักงาน"
        },
        {
            id: "HOUSING_ALLOWANCE",
            value: "ค่าใช้จ่ายสำหรับที่อยู่อาศัย"
        },
        {
            id: "TRAVEL_ALLOWANCE",
            value: "ค่าใช้จ่ายในการเดินทาง"
        },
        {
            id: "OVERTIME_PAY",
            value: "ค่าทำงานล่วงเวลา"
        },
        {
            id: "MEDICAL_INSURANCE",
            value: "ประกันสุขภาพ"
        },
        {
            id: "LIFE_INSURANCE",
            value: "ประกันชีวิต"
        },
        {
            id: "DENTAL_INSURANCE",
            value: "สิทธิการเบิกค่าทันตกรรม"
        },
        {
            id: "GRATUITY",
            value: "ค่าตอบแทนพิเศษ"
        },
        {
            id: "WORK_FROM_HOME",
            value: "ทำงานจากที่บ้าน"
        },
        {
            id: "FIVE_DAY_WORK_WEEK",
            value: "ทำงานสัปดาห์ละ 5 วัน"
        },
        {
            id: "TRANSPORTATION_ALLOWANCE",
            value: "ค่ายานพาหนะ"
        },
        {
            id: "PERFORMANCE_BONUS",
            value: "เงินโบนัสตามผลงาน"
        },
        {
            id: "ACCOMMODATION",
            value: "ที่พักพนักงาน"
        },
        {
            id: "OTHER",
            value: "อื่นๆ"
        }
    ]
    const getJobDetail = () => {

        if (job_id) {
            ApiServices().getJobDetail(job_id).then((res: JobDetailModel) => {
                console.log(res);
                UtilityService().clickSendEvent("view", "Apply Job", "View Job " + res.jobRole.name);
                setJobDetailModel(res);
                setCenter({ lat: res.latitude, lng: res.longitude });
                const arrayBenefits = new Array<String>();
                res.benefits?.map((res: any, index: any) => {
                    benefitsArray.map((res2: any) => {
                        console.log(res);
                        if (res === res2.id) {
                            arrayBenefits.push(res2.value);
                            console.log(res2.value);
                        }
                    })


                })

                setBenefit(arrayBenefits);
                // let benefitsplit = res.benefits.split('<ul>')
                // let benefitsplit2 = res.benefits.split('</ul>')
                // console.log(benefitsplit2[0].split('<li>'));
                // console.log(benefitsplit2[0].split('<li>'));
                // benefitsplit2[0].split('<li>').map((res: any, index: number) => {

                //     if (index > 0 && index < 8) {
                //         setBenefit(benefit => [...benefit, res]);
                //     }
                // })
            })
        }

    }
    function createMarkup(data: string) {
        return { __html: data };
    }
    const genderArray = [
        {
            id: "",
            value: "กรุณาเลือก"
        },
        {
            id: "ชาย",
            value: "ชาย"
        },
        {
            id: "หญิง",
            value: "หญิง"
        },
        {
            id: "ไม่ระบุ",
            value: "ไม่ระบุ"
        },
    ]
    const eduArray = [
        {
            id: "",
            value: "กรุณาเลือก"
        },
        {
            id: "ต่ำกว่ามัธยมศึกษา",
            value: "ต่ำกว่ามัธยมศึกษา"
        },
        {
            id: "มัธยมศึกษาตอนต้น",
            value: "มัธยมศึกษาตอนต้น"
        },
        {
            id: "มัธยมศึกษาตอนปลาย",
            value: "มัธยมศึกษาตอนปลาย"
        },
        {
            id: "ปวช.",
            value: "ปวช."
        },
        {
            id: "ปวท.",
            value: "ปวท."
        },
        {
            id: "ปวส.",
            value: "ปวส."
        },
        {
            id: "อนุปริญญา",
            value: "อนุปริญญา"
        },
        {
            id: "ปริญญาตรี",
            value: "ปริญญาตรี"
        },
    ]


    const onSubmit = async (values) => {


        if (values.firstname && values.lastname && values.tel && values.dob && values.education && values.gender && values.pdpa) {
            if (!isError) {



                setIsLoading(true);
                onOpen();
            
                var dateobj =
                    new Date(values.dob);
                var dobISO = dateobj.toISOString();
                var questionArray = new Array<String>();

                if (jobDetailModel?.questions) {

                    jobDetailModel.questions.map((res: any, index: any) => {
                        var strQ = "question" + index;
                        questionArray.push(values[strQ] + "")
                    })
                }


                // if(values.)


                const applyJobObj: ApplyJobModel = {
                    firstName: values.firstname,
                    lastName: values.lastname,
                    phone: values.tel,
                    email: values.email,
                    dateOfBirth: dobISO,
                    education: values.education,
                    gender: values.gender,
                    profileImageUrl: "",
                    resumeUrl: "",
                    answer: questionArray,
                    experiences: values.workexp
                }
                if (file) {
                    handleUpload(applyJobObj)
                } else if (fileImg) {
                    handleUploadProfile(applyJobObj)

                } else {
                    ApiServices().ApplyJobs(applyJobObj, job_id).then((res: any) => {
                        setIsLoading(false);
                        setIsComplete(true);
                        UtilityService().clickSendEvent("Submit", "Apply Job", "Apply Job Success");
                        navigate('/jobseeker/apply/complete?isApply=true')
                    })
                }
            }
        }

    }
    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )
    const onSelectFileProfile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const imageFile = e.target.files[0];
            console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
            console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

            const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1000,
                useWebWorker: true
            }
            try {
                const compressedFile = await imageCompression(imageFile, options);
                console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

                setFileImg(compressedFile);
                onOpen();
                setCrop(undefined) // Makes crop preview update between images.
                const reader = new FileReader()
                reader.addEventListener('load', () =>
                    setImgSrc(reader.result!.toString() || ''),
                )
                reader.readAsDataURL(compressedFile)


            } catch (error) {
                console.log(error);
            }


        }
    }
    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            console.log(e.target.files[0])

            if (e.target.files[0].size > 2097152) {
                setIsMaxSizeError(true);
                onOpen();
            } else {
                setIsMaxSizeError(false);
                setFile(e.target.files[0]);

            }

        }
    }
    const onCloseError = () => {
        setIsMaxSizeError(false);
        onClose();
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }
    const deleteFile = () => {

        setFile(undefined);
    }
    const clickCrop = () => {
        setIsCrop(true);
        onClose();
    }
    const sanitizedData = (data) => ({
        __html: DOMPurify.sanitize(data)
      })
    const handleUpload = (values: ApplyJobModel) => {

        if (file) {


            const storageRef = ref(storage, `/files/${file.name}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        values.resumeUrl = url;


                        if (fileImg) {
                            handleUploadProfile(values);
                        } else {
                            postJobAPI(values)
                        }

                    });
                }
            );
        }


    };

    const handleUploadProfile = (values: ApplyJobModel) => {

        if (fileImg) {

            var currentTimeInSeconds = Math.floor(Date.now() / 1000);
            const storageRef = ref(storage, `/profile-img/${currentTimeInSeconds}`);

            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, fileImg);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    console.log(percent);
                    // setPercent(percent);
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        values.profileImageUrl = url;
                        console.log(url);
                        postJobAPI(values)
                    });
                }
            );
        }


    };

    const postJobAPI = (values) => {

        ApiServices().ApplyJobs(values, job_id).then((res: any) => {
            setIsLoading(false);
            setIsComplete(true);
            UtilityService().clickSendEvent("submit", "Apply Job", "Apply Job Success");
            navigate('/jobseeker/apply/complete?isApply=true')
        })

    }

    const onKeyPressed = (e) => {
        console.log(e.key);
        return false
    }
    return (

        <>
            <div className="apply-job d-flex flex-column">
                {
                    appName === "jobnow" && <HeaderComponent></HeaderComponent>
                }

                <div className="bg-control">

                    <div className="container container-control">

                        <img src={TOP_COVER} className="img-top-cover"></img>

                        <div className="form-control">
                            <span className="text-header"> กรอกประวัติเพื่อสมัครงาน</span>
                            <div className="divider-gray"></div>
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-column">

                                    <div className="d-flex">
                                        <span className="text-header">ตำแหน่ง:  {jobDetailModel?.name}</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="text-header my-auto">บริษัท: {jobDetailModel?.companyName} </span>
                                        {/* <span className="text-inner my-auto" dangerouslySetInnerHTML={createMarkup(jobDetailModel?.headquarterName + "")}></span> */}
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="text-header my-auto">รายละเอียด:  </span>
                                        <span className="text-inner my-auto" dangerouslySetInnerHTML={createMarkup(jobDetailModel?.description + "")}></span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="text-header my-auto">สถานที่ทำงาน:  </span>
                                        <span className="text-inner my-auto" dangerouslySetInnerHTML={createMarkup(jobDetailModel?.googlePlaceName + "")}></span>
                                        {
                                            center ?
                                                <GoogleMap
                                                    mapContainerStyle={containerStyle}
                                                    center={center}
                                                    zoom={15}
                                                    onLoad={onLoad}
                                                    onUnmount={onUnmount}
                                                >
                                                    { /* Child components, such as markers, info windows, etc. */}
                                                    <MarkerF position={center}></MarkerF>
                                                </GoogleMap>
                                                : null
                                        }


                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="text-header my-auto">คุณสมบัติ:  </span>
                                        <ul>
                                            {
                                                jobDetailModel?.educationFrom ?
                                                    <li className="text-inner my-auto" >


                                                        {
                                                            jobDetailModel?.educationFrom.nameTH === "ไม่ระบุ" ?

                                                                "ไม่ระบุการศึกษาขั้นต่ำ" :

                                                                "การศึกษาระดับ" + jobDetailModel?.educationFrom.nameTH
                                                        }
                                                    </li> : null
                                            }
                                            {
                                                jobDetailModel?.experienceFrom ?
                                                    <li className="text-inner my-auto" >
                                                        {
                                                            "ประสบการณ์ขั้นต่ำ " + jobDetailModel?.experienceFrom + " ปี"
                                                        }

                                                    </li> : null
                                            }
                                            {

                                                jobDetailModel?.bikeLicense ?

                                                    <li className="text-inner my-auto">มีใบขับขี่จักรยานยนต์
                                                    </li> : null
                                            }

                                            {

                                                jobDetailModel?.carLicense ?

                                                    <li className="text-inner my-auto">มีใบขับขี่รถยนต์
                                                    </li> : null
                                            }
                                            {

                                                jobDetailModel?.truckLicense ?

                                                    <li className="text-inner my-auto">มีใบขับขี่รถบรรทุก
                                                    </li> : null
                                            }

                                            {

                                                jobDetailModel?.gender === "MALE" ?

                                                    <li className="text-inner my-auto">เพศชาย
                                                    </li> : jobDetailModel?.gender === "FEMALE" ? <li className="text-inner my-auto">เพศหญิง
                                                    </li> : <li className="text-inner my-auto">ไม่ระบุเพศ
                                                    </li>
                                            }

                                            {
                                                jobDetailModel?.ageTo ?
                                                    jobDetailModel?.ageFrom === jobDetailModel?.ageTo ?
                                                        <li className="text-inner my-auto">อายุ
                                                            {jobDetailModel?.ageTo} ปี
                                                        </li> : <li className="text-inner my-auto">อายุระหว่าง {jobDetailModel?.ageFrom} - {jobDetailModel?.ageTo} ปี
                                                        </li>
                                                    : null

                                            }

                                        </ul>
                                    </div>
                                    {
                                        jobDetailModel?.benefits ?
                                            <div className="d-flex flex-column">
                                                <span className="text-header my-auto">สวัสดิการ:  </span>

                                                {
                                                    benefit?.map((res: String, index: any) => {

                                                        return (

                                                            <li className="text-inner my-auto">
                                                                {res.replace("</li>", "")}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </div> : null
                                    }

                                </div>

                            </div>
                            <Form onSubmit={onSubmit}
                                validate={values => {


                                    const errors: any = {
                                        firstname: "", lastname: "", tel: "", dob: "",
                                        email: "", education: "", gender: "", resume: "", pdpa: "", workexp : ""
                                    }
                                    setIsError(false);
                                    jobDetailModel?.questions.map((res: any, index: any) => {
                                        errors["question" + index] = "";

                                        if (!values["question" + index]) {
                                            setIsError(true);
                                            errors["question" + index] = 'กรุณากรอก'
                                        }
                                    })
                                    if (!values.firstname) {
                                        setIsError(true);
                                        errors.firstname = 'กรุณากรอก'
                                    }
                                    if (!values.workexp) {
                                        setIsError(true);
                                        errors.workexp = 'กรุณากรอก'
                                    }
                                    if (!values.lastname) {
                                        setIsError(true);
                                        errors.lastname = 'กรุณากรอก'
                                    }
                                    if (!values.tel) {
                                        setIsError(true);
                                        errors.tel = 'กรุณากรอก'
                                    }
                                    if (!values.dob) {
                                        setIsError(true);
                                        errors.dob = 'กรุณากรอก'
                                    }



                                    if (!values.education) {
                                        setIsError(true);
                                        errors.education = 'กรุณาเลือก'
                                    }
                                    if (!values.gender) {
                                        setIsError(true);
                                        errors.gender = 'กรุณาเลือก'
                                    }
                                    if (!values.pdpa) {
                                        setIsError(true);
                                        errors.pdpa = 'กรุณาเลือก'
                                    }
                                    if (values.email) {
                                        var emailReg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
                                        if (!emailReg.test(values.email)) {
                                            setIsError(true);
                                            errors.email = 'อีเมล ผิดรูปแบบ ตัวอย่าง. abc@test.com'
                                        }
                                    }

                                    console.log(errors);

                                    return errors
                                }}
                                render={({ handleSubmit, form, submitting, pristine, values }) => (


                                    <form onSubmit={handleSubmit}>
                                        <div className="d-flex">
                                            <Field name="profileImg">
                                                {({ input, meta }) => (
                                                    <div className="form-item-left d-flex flex-column">
                                                        <label className="text-header">อัปโหลดรูปตัวเอง (ถ้ามี)<span className="required-color"></span></label>
                                                        {Boolean(completedCrop) && (
                                                            <canvas
                                                                ref={previewCanvasRef}
                                                                className="img-crop-complete"
                                                            />


                                                        )}
                                                        <input type="file" {...input} className="choose-file" accept="image/*" onChange={onSelectFileProfile} />

                                                        {isSubmit && file === undefined && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>

                                        </div>
                                        <div className="form-grid-control">
                                            <Field name="firstname">
                                                {({ input, meta }) => (
                                                    <div className="form-item-left">
                                                        <label className="text-header">ชื่อจริง <span className="required-color">*</span></label>
                                                        <input {...input} type="text" className="input-form" placeholder="" />
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                            <Field name="lastname">
                                                {({ input, meta }) => (
                                                    <div className="form-item-right">
                                                        <label className="text-header">นามสกุล <span className="required-color">*</span></label>
                                                        <input {...input} type="text" className="input-form" placeholder="" />
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="form-grid-control">
                                            <Field name="tel">
                                                {({ input, meta }) => (
                                                    <div className="form-item-left">
                                                        <label className="text-header">เบอร์โทรศัพท์ที่ติดต่อได้ <span className="required-color">*</span></label>
                                                        <input {...input} type="number" className="input-form" placeholder="" />
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                            <Field name="email">
                                                {({ input, meta }) => (
                                                    <div className="form-item-left">
                                                        <label className="text-header">อีเมล (ถ้ามี) <span className="required-color"></span></label>
                                                        <input {...input} type="text" className="input-form" placeholder="" />
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>

                                        <div className="form-grid-control">
                                            <Field name="dob">
                                                {({ input, meta }) => (
                                                    <div className="form-item-left">
                                                        <label className="text-header">วัน/เดือน/ปีเกิด <span className="required-color">*</span></label>
                                                        <input {...input} type="date" className="input-form" placeholder="" min={"1922-01-01"}
                                                            max={"2005-12-31"}
                                                        />
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                            <Field name="gender">
                                                {({ input, meta }) => (
                                                    <div className="form-item-right">
                                                        <label className="text-header">เพศ <span className="required-color">*</span></label>
                                                        <div className="select-control">
                                                            <Field name="gender" component="select">
                                                                {
                                                                    genderArray.map((res: any, index: any) => {

                                                                        return (
                                                                            <option value={res.id} key={index}>{res.value}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </Field>
                                                        </div>
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>

                                        </div>

                                        {/* <div className="form-grid-control">
                                            <Field name="weight">
                                                {({ input, meta }) => (
                                                    <div className="form-item-left">
                                                        <label className="text-header">น้ำหนัก <span className="required-color">*</span></label>
                                                        <input {...input} type="number" className="input-form" placeholder="" />
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                            <Field name="height">
                                                {({ input, meta }) => (
                                                    <div className="form-item-right">
                                                        <label className="text-header">ส่วนสูง <span className="required-color">*</span></label>
                                                        <input {...input} type="number" lang="TH" className="input-form" placeholder="" />
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div> */}

                                        <div className="form-grid-control">
                                            <Field name="education">
                                                {({ input, meta }) => (
                                                    <div className="form-item-left">
                                                        <label className="text-header">วุฒิการศึกษา <span className="required-color">*</span></label>
                                                        <div className="select-control">
                                                            <Field name="education" component="select">
                                                                {
                                                                    eduArray.map((res: any, index: any) => {
                                                                        return (
                                                                            <option value={res.id} key={index}>{res.value}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </Field>
                                                        </div>
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>

                                        </div>

                                        <div className="form-grid-control  full-grid">
                                            <Field name="workexp">
                                                {({ input, meta }) => (
                                                    <div className="form-item-left">
                                                        <label className="text-header">ประสบการณ์ทำงาน <span className="required-color">*</span></label>
                                                        <label className="text-desc-grey">(แนะนำให้ระบุ ชื่อตำแหน่ง บริษัท ระยะเวลาที่ปฏิบัติงาน และรายละเอียดหน้าที่ความรับผิดชอบ หากไม่มีให้ใส่ "ไม่มี")</label>
                                                        <textarea {...input}  lang="TH" placeholder="" rows={5}  className="teatarea-custom" />
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>

                                        </div>
                                        <div className="form-grid-control full-grid">
                                            <Field name="resume">
                                                {({ input, meta }) => (
                                                    <div className="form-item-left">
                                                        <label className="text-header">อัปโหลดเรซูเม่ - ประวัติย่อ (ถ้ามี)<span className="required-color"></span></label>


                                                        {
                                                            file ?

                                                                <div className="grid-file-control">
                                                                    <label className="text-link-file">{file.name}</label>
                                                                    <label className="delete-text ml-auto" onClick={deleteFile}>ลบ</label>
                                                                </div>
                                                                :
                                                                <div className="d-flex flex-column">
                                                                    <label className="text-desc-grey">รองรับไฟล์ .pdf, .doc, .docx, .jpg และต้องขนาดไม่เกิน 2MB</label>
                                                                    <input type="file" {...input} accept="image/png,application/pdf,image/jpg,application/msword" className="choose-file" onChange={onSelectFile} />
                                                                </div>

                                                        }

                                                        {isSubmit && file === undefined && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>

                                        </div>
                                        {
                                            jobDetailModel ?
                                                jobDetailModel?.questions.length > 0 ?
                                                    <div className="prescreen-control">
                                                        <span className="text-header">คำถามคัดกรองผู้สมัคร</span>
                                                        <div className="divider-gray"></div>

                                                        {

                                                            jobDetailModel.questions.map((res: any, index: number) => {

                                                                return (
                                                                    <Field name={"question" + index} key={index}>
                                                                        {({ input, meta }) => (
                                                                            <div className="form-item">
                                                                                <span className="text-header">คำถามที่ {index+1}  <span className="required-color">*</span></span>
                                                                               
                                                                                <div className="text-header" dangerouslySetInnerHTML={sanitizedData(res.question)}></div>
                                                                              
                                                                                <textarea {...input} rows={3} lang="TH" placeholder="" className="input-form-text-area" />
                                                                                {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>

                                                                )
                                                            })
                                                        }


                                                    </div> : null
                                                : null

                                        }
                                        <div className="form-grid-control full-grid">
                                            <Field name="pdpa">
                                                {({ input, meta }) => (
                                                    <div className="form-item-agreement d-flex flex-column">
                                                        <div className="d-flex">
                                                            <Field
                                                                name="pdpa"
                                                                component="input"
                                                                type="checkbox"
                                                                value="pdpa"
                                                            />{' '}
                                                            <label className="text-agreement">ฉันได้อ่านและยอมรับ
                                                                <a href="https://scoutout.co/terms-th/" target="_blank">ข้อกำหนด/เงื่อนไขการใช้งาน</a> และ
                                                                <a href="https://scoutout.co/privacy-th/" target="_blank">นโยบายความเป็นส่วนตัว</a>
                                                            </label>
                                                        </div>
                                                        {meta.error && meta.touched && <span className="error-text">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>


                                        <button className="button-submit" type="submit" onClick={() => onSubmit(values)}>ส่งใบสมัคร</button>

                                    </form>

                                )}
                            />

                        </div>

                    </div>

                </div>
            </div>

            {
                isMaxSizeError ? <FileMaxSizeModal isOpen={isOpen} onClose={onClose} onCloseError={onCloseError}></FileMaxSizeModal> : null
            }
            {
                isLoading ? <LoadingMessage isOpen={isOpen} onClose={onClose}></LoadingMessage> :

                    isComplete && !isLoading ? <CompleteModal isOpen={isOpen} onClose={onClose} isApply={true}></CompleteModal> :
                        <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>เลือกตำแหน่งของ Logo บริษัท</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <ReactCrop
                                        crop={crop}
                                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                                        onComplete={(c) => setCompletedCrop(c)}
                                        aspect={aspect}
                                        circularCrop={true}
                                    >
                                        <img
                                            ref={imgRef}
                                            alt="Crop me"
                                            className="img-crop"
                                            src={imgSrc}
                                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                            onLoad={onImageLoad}
                                        />

                                    </ReactCrop>
                                    <button className="button-crop-submit" onClick={clickCrop}>ตกลง</button>
                                </ModalBody>


                            </ModalContent>
                        </Modal>
            }

        </>
    )

}


export default ApplyJobComponent;