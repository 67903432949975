

import "./header.scss";
import JOBNOWLOGO from "../../assets/images/jobnow-logo.png";
import React from "react";
import UtilityService from "../../utils/utility";
import config from "../../config";

const HeaderComponent = () => {
    let redirect_login = (new URLSearchParams(window.location.search)).get("redirect_login")
  

    React.useEffect(() => {
        console.log(redirect_login);

    }, [])

    const clickLogin = () => {

        if(redirect_login){
            UtilityService().clickSendEvent("Click", "Header" , "Login SSO");
            console.log(redirect_login);
            window.open(redirect_login, "_self");
        }
        
    }
    const clickJobNow = () => {

        if(redirect_login){
            UtilityService().clickSendEvent("Click", "Header" , "Go to Jobnow");
           
            window.open(config.link.jobnow, "_self");
        }
        
    }


    return (
        <>

        <div className="header">
            <div className="header-control">

                <div className="container-control">

                    <div className="grid-control">
                        <div className="d-flex my-auto">
                        <img className="logo-control" src={JOBNOWLOGO} onClick={clickJobNow} ></img>
                        </div>

                        <div className="d-flex">
                            <button className="button-login" onClick={clickLogin}>เข้าสู่ระบบ / สมัครสมาชิก</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        </>
    )




}







export default HeaderComponent;